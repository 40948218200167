var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"font-size-md mt-5 welcomeText",staticStyle:{"color":"#01041b"}},[_vm._v(" "+_vm._s(_vm.getWelcomeText())+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm.getUserRoleText()))])]),_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.currentUser || !_vm.currentUser.t},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{staticClass:"card card-custom gutter-b"},[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}})],1)]},proxy:true}])},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/' && isActive && 'menu-item-active',
            _vm.$route.path == '/' && isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ana Sayfa ")])])])]}}])}),_c('router-link',{attrs:{"to":"/hatirlatmalar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/hatirlatmalar' && isActive && 'menu-item-active',
            _vm.$route.path == '/hatirlatmalar' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Teklifler")])])])]}}])}),_c('router-link',{attrs:{"to":"/teklif"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/teklif' && isActive && 'menu-item-active',
            _vm.$route.path == '/teklif' && isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tekliflerim")])])])]}}])}),_c('router-link',{attrs:{"to":"/bayiler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/bayiler' && isActive && 'menu-item-active',
            _vm.$route.path == '/bayiler' && isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bayiler")])])])]}}])}),_c('router-link',{attrs:{"to":"/musteriler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return undefined}}])}),_c('router-link',{attrs:{"to":"/teknik-destek"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-support"}),_c('span',{staticClass:"menu-text"},[_vm._v("Teknik Destek")])])])]}}])}),_c('li',{staticClass:"menu-item",class:[
          _vm.isActive && 'menu-item-active',
          _vm.isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"menu-icon flaticon-logout"}),_c('span',{staticClass:"menu-text"},[_vm._v("Çıkış Yap")])])]),_c('hr',{staticClass:"menu-line d-none"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }