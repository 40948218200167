<template>
  <div>
    <div class="font-size-md mt-5 welcomeText" style="color: #01041b">
      {{ getWelcomeText() }}
      <br />
      <small>{{ getUserRoleText() }}</small>
    </div>

    <b-skeleton-wrapper :loading="!currentUser || !currentUser.t">
      <template #loading>
        <b-card class="card card-custom gutter-b">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      <ul class="menu-nav">
        <router-link
          to="/"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/' && isActive && 'menu-item-active',
              $route.path == '/' && isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Ana Sayfa </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/hatirlatmalar"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/hatirlatmalar' && isActive && 'menu-item-active',
              $route.path == '/hatirlatmalar' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Teklifler</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/teklif"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/teklif' && isActive && 'menu-item-active',
              $route.path == '/teklif' && isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Tekliflerim</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/bayiler"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/bayiler' && isActive && 'menu-item-active',
              $route.path == '/bayiler' && isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Bayiler</span>
            </a>
          </li>
        </router-link>
      
        <router-link
          to="/musteriler"
          v-slot="{ href, navigate, isActive, isExactActive }"
        ></router-link>
        <router-link
          to="/teknik-destek"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-support"></i>
              <span class="menu-text">Teknik Destek</span>
            </a>
          </li>
        </router-link>
        <!-- <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/manage'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-home"></i
            ><span class="menu-text">Yönetim</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Yönetim</span></span
                >
              </li>
              <router-link
                to="/yonetim/mulkler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Mülkler </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/yonetim/kiracilar"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kiracılar </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/yonetim/musteriler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Müşteriler </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li> -->
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a class="menu-link" @click="onLogout">
            <i class="menu-icon flaticon-logout"></i>
            <span class="menu-text">Çıkış Yap</span>
          </a>
        </li>
        <hr class="menu-line d-none" />
      </ul>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "DPMenu",
  data() {
    return {
      isActive: false,
      isExactActive: false,
      navigate: "",
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    getWelcomeText() {
      if (!this.currentUser || !this.currentUser.f) return;

      let w = "Merhaba, ";

      var day = new Date();
      var hr = day.getHours();
      if (hr >= 6 && hr < 12) {
        w = "Günaydın ";
      } else if (hr >= 12 && hr < 17) {
        w = "İyi günler ";
      } else if (hr >= 17 && hr < 22) {
        w = "İyi Akşamlar ";
      } else {
        w = "İyi Geceler ";
      }
      return w + this.currentUser.f.split(" ")[0];
    },
    getUserRoleText() {
      if (!this.currentUser || !this.currentUser.f) return;

      return this.$customFunctions.getUserTypeText(this.currentUser.t);
    },
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (window.location = "/giris"));
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

@media (min-width: 990px) {
  #logout {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
  }
}

.menu-line {
  color: #e8e8e8;
  margin: 10px 25px;
}

.menu-item {
  margin-top: 0.5rem !important;

  .menu-link {
    border-radius: 0rem 0.46154rem 0.46154rem 0rem;
    padding: 1.31rem !important;

    .menu-text,
    .menu-icon {
      font-size: 1.23077rem !important;
      letter-spacing: 0.02462rem !important;
      font-weight: 400 !important;
    }
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active {
  .menu-link,
  .menu-text,
  .menu-icon {
    background-color: $dveb-info !important;
    color: $dveb-menu-text-color !important;
    font-size: 1.23077rem !important;
    font-weight: 600 !important;
  }

  .menu-link {
    &:hover {
      .menu-text,
      .menu-icon {
        background-color: $dveb-info !important;
        color: $dveb-menu-text-color !important;
      }
    }
  }
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover {
  .menu-link,
  .menu-text,
  .menu-icon {
    background-color: $dveb-info !important;
    color: $dveb-menu-text-color !important;
  }
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: $dveb-info;
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-icon {
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link {
  background-color: $dveb-info !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-icon {
  background-color: $dveb-info !important;
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-text {
  background-color: $dveb-info !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: $dveb-menu-text-color !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link {
  background-color: $dveb-info !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: $dveb-menu-text-color !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link {
  background-color: $dveb-info !important;
}
.menu-item.menu-item-active span {
  color: $dveb-menu-text-color !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-bullet {
  color: $dveb-menu-text-color !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  color: $dveb-menu-text-color !important;
}
</style>
